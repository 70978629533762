.myCanvas {
    height: 50%;
    width: 100%;
    border:1px solid #000000;
}
.m-signature-pad {
    font-size: 10px;
    width: 100%;
    border-radius: 4px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .m-signature-pad--body {
    left: 20px;
    right: 20px;
    bottom: 60px;
    display: flex;
    width: 100%;
    background-color: #eeeeee;
    margin-top: 30px;
  }

  .m-signature-pad--body
    canvas {
      margin: auto;
      width: 100%;
      height: 250px;
      border-radius: 4px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
    }

  .m-signature-pad--footer
    .description {
      color: rgb(231, 23, 23);
      text-align: center;
      font-size: 1.2em;
      margin-top: 1.8em;
    }

  .m-signature-pad--footer
    .button {
      position: absolute;
      bottom: 0;
    }

  .m-signature-pad--footer
    .button.clear {
      left: 0;
    }

  .m-signature-pad--footer
    .button.save {
      right: 0;
    }

  @media screen and (max-width: 1024px) {
    .m-signature-pad {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      min-width: 250px;
      margin: 15px 0;
    }

    #github {
      display: none;
    }
  }

  @media screen and (max-width: 425px) {
    .m-signature-pad,
    .m-signature-pad--body,
    .m-signature-pad--body canvas {
      height: 200px;
    }
  }

  @media screen and (max-height: 320px) {
    .m-signature-pad--body {
      left: 0;
      right: 0;
      top: 0;
      bottom: 32px;
    }
    .m-signature-pad--footer {
      left: 20px;
      right: 20px;
      bottom: 4px;
      height: 28px;
    }
    .m-signature-pad--footer
      .description {
        font-size: 1em;
        margin-top: 1em;
      }
  }