/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a {
  text-decoration: none;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/** AFTER RESET **/

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  color: #303030;
  font-size: 16px;
  line-height: 21px;
  background: #f5f6f5;
}

button,
input,
textarea {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

/** PHOTO SLIDER **/

.photo-carousel {
  height: 500px;
  margin-top: 0px;
  overflow: hidden;
}

.photo-carousel .photo-carousel-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: 50% 50%;
}

.photo-carousel .slick-prev {
  left: 25px;
}

.photo-carousel .slick-next {
  right: 25px;
}

.photo-carousel .slick-next,
.photo-carousel .slick-prev {
  z-index: 100;
  width: 40px;
  height: 40px;
}

.photo-carousel .slick-next:before,
.photo-carousel .slick-prev:before {
  font-size: 35px;
  color: rgb(255, 255, 255);
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2));
}

.photo-carousel .slick-prev:hover:before,
.photo-carousel .slick-prev:focus:before,
.photo-carousel .slick-next:hover:before,
.photo-carousel .slick-next:focus:before {
  opacity: 0.8;
}

.photo-carousel .slick-track div:focus {
  outline: 0;
}

.photo-carousel .slick-dots {
  bottom: 25px;
}

.photo-carousel .slick-slider.center .slick-slide {
  opacity: 0.4;
}

.photo-carousel .slick-slider.center .slick-slide.slick-current {
  opacity: 1;
}

.photo-carousel .slick-dots li {
  margin: 0;
  width: 11px;
  height: 11px;
}

.photo-carousel .slick-dots li button:before {
  color: #fff;
  font-size: 6px;
  opacity: 0.8;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2));
}

.photo-carousel .slick-dots li.slick-active button:before {
  color: #fff;
  font-size: 9px;
  opacity: 1;
  line-height: 22px;
}

@media (max-width: 1025px) {
  .photo-carousel {
    height: 400px;
  }
  .photo-carousel .photo-carousel-image {
    height: 400px;
  }
  .photo-carousel .slick-next:before,
  .photo-carousel .slick-prev:before {
    font-size: 30px;
  }
  .photo-carousel .slick-slider.center .slick-slide {
    opacity: 1;
  }
}

@media (max-width: 599px) {
  .photo-carousel {
    height: 360px;
  }
  .photo-carousel .photo-carousel-image {
    height: 360px;
  }
  .photo-carousel .slick-dots {
    bottom: 22px;
  }
  .photo-carousel .slick-dots li {
    width: 11px;
    height: 11px;
  }
  .photo-carousel .slick-dots li button:before {
    font-size: 6px;
  }
  .photo-carousel .slick-dots li.slick-active button:before {
    font-size: 9px;
    line-height: 22px;
  }
}

@media (max-width: 479px) {
  .photo-carousel {
    height: 290px;
  }
  .photo-carousel .photo-carousel-image {
    height: 290px;
  }
  .photo-carousel .slick-prev {
    left: 5px;
  }
  .photo-carousel .slick-next {
    right: 5px;
  }
}

/** MODAL **/

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: '';
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 425px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

/** Menu dropdown Popper position fix **/

@media screen and (max-width: 767px) {
  .header-menu-dropdown {
    transform: translate3d(0px, 70px, 0px) !important;
  }
}

.flex-full {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}
